<template>
  <div id="app">
    <div
      class="container"
      :class="{ 'is-desktop': isDesktop, 'is-mobile': isMobile }"
    >
      <div class="header">
        <img :src="publicPath('logo.png')" alt="logo" class="logo" />
      </div>
      <div class="content">
        <div class="content-text">
          <h1>RTM : une nouvelle web app pour vous !</h1>
          <hr />
          <p>
            Votre média RTM est désormais disponible sur votre nouvelle web app
            Les Ondes M. Pour accéder au contenu RTM rendez-vous sur
            les-ondes-m.com
          </p>
          <div class="content-action">
            <a href="https://www.les-ondes-m.com/?utm_source=redirect&utm_medium=rtm&utm_campaign=migration" class="button"> Accéder aux Ondes-M </a>
          </div>
        </div>
        <div class="content-image">
          <img :src="publicPath('picto.svg')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";

export default {
  name: "App",
  data() {
    return {
      isDesktop: !isMobile,
      isMobile,
    };
  },
  methods: {
    publicPath(file) {
      return process.env.BASE_URL + file;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700;900&display=swap");

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #353535;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.logo {
  height: 120px;
}

.container {
  max-width: 100%;
  width: 1080px;
  padding: 20px;
  box-sizing: border-box;
}

.container.is-desktop {
  padding-top: 120px;
}

.content {
  display: flex;
  text-align: left;
  align-items: stretch;
}

.content > div {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content h1 {
  line-height: 1.15em;
  font-size: 46px;
  font-weight: 800;
}

.content hr {
  border: none;
  width: 150px;
  max-width: 100%;
  height: 6px;
  background: #f9d815;
  margin: 16px 0;
  margin-top: 32px;
}

.content p {
  line-height: 1.5em;
  max-width: 100%;
  width: 420px;
  margin: 42px 0;
  font-weight: 600;
  font-size: 17px;
}

.content .button {
  background: #f9d815;
  color: #353535;
  text-decoration: none;
  padding: 12px 18px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
}

.content-text {
  flex-grow: 1;
}

.content-image {
  width: 420px;
  max-width: 100%;
}

.content-image img {
  margin: 20px;
}

/** mobile now **/

.container.is-mobile .content {
  flex-direction: column;
}
</style>
